<template>

    <main ref="resumen" id="resumen">
       <div class="card">
           <div class="card-body">
                <modalInformacionUsuario :usuario="usuario"></modalInformacionUsuario>
                <div class="mt-2">
                    <cardInformacionUsuario :usuario="usuario"></cardInformacionUsuario>
                </div>
                <div class="py-2"><motivoConsultaShow :idHistoria="idHistoria"></motivoConsultaShow></div>
                <div class="py-2"><examenFisicoShow :idHistoria="idHistoria"></examenFisicoShow></div>
                <div class="py-2"><examenFisicoEmbarazoShow :idHistoria="idHistoria"></examenFisicoEmbarazoShow></div>
                <div class="py-2"><exploracionFisicaShow :idHistoria="idHistoria"></exploracionFisicaShow></div>
                <div class="py-2"><ordenClinicaShow :idHistoria="idHistoria"></ordenClinicaShow></div>
                <div class="py-2"><formulaMedicaShow :idHistoria="idHistoria"></formulaMedicaShow></div>
                <div class="py-2"><impresionDxShow :idHistoria="idHistoria"></impresionDxShow></div>
                <div class="py-2"><riesgoBiopsicosocialShow :idHistoria="idHistoria"></riesgoBiopsicosocialShow></div>
                <div class="py-2"><vacunasShow :idHistoria="idHistoria" :idUsuario="idUsuario"></vacunasShow></div>
                <div class="py-2"><examenesShow :idHistoria="idHistoria" :idUsuario="idUsuario"></examenesShow></div>
                <div class="py-2"><embarazoActualShow :idHistoria="idHistoria" :idUsuario="idUsuario"></embarazoActualShow></div>
                <div class="py-2"><antecedenteObstetricoShow :idEmbarazo="ultimo_embarazo.id"></antecedenteObstetricoShow></div>
            
                <button class="btn btn-lg btn-primary" @click="download()">Imprimir</button>
                <img :src="imgData">
            </div>
       </div>
    </main>

</template>
<script>
import motivoConsultaShow from "../base/motivoConsulta/motivoConsultaShow";
import examenFisicoShow from "../base/examenFisico/examenFisicoShow";
import ordenClinicaShow from "../base/orden/ordenClinicaShow";
import formulaMedicaShow from "../base/formulaMedica/formulaMedicaShow";
import impresionDxShow from "../base/impresionDX/impresionDxShow";
import riesgoBiopsicosocialShow from "../base/baseRiesgobiopsicosocial/index/riesgoBiopsicosocialShow";
import vacunasShow from "../base/baseVacunas/index/vacunasShow";
import examenesShow from "../base/baseReporteExamenes/index/examenesShow";
import embarazoActualShow from "../base/baseEmbarazoActual/embarazoActualShow";
import exploracionFisicaShow from "../base/examenFisico/exploracionFisicaShow";
import examenFisicoEmbarazoShow from "../base/examenFisico/examenFisicoEmbarazoShow";
import { jsPDF } from "jspdf";
import historiaClinicaService from '../../../services/historiaClinicaService';
import modalInformacionUsuario from "../base/informacionUsuario/modalInformacionUsuario";
import cardInformacionUsuario from "../base/informacionUsuario/cardInformacionUsuario";
import usuarioService from '../../../services/usuarioService';
import antecedenteObstetricoShow from "../base/baseEmbarazo/antecedenteObstetricoShow";
import embarazoService from '../../../services/embarazoService';

export default {
    components:{motivoConsultaShow,
    examenFisicoShow,
    ordenClinicaShow,
    formulaMedicaShow,
    impresionDxShow,
    riesgoBiopsicosocialShow,
    vacunasShow,
    examenesShow,
    embarazoActualShow,
    exploracionFisicaShow,
    examenFisicoEmbarazoShow,
    modalInformacionUsuario,
    cardInformacionUsuario,
    antecedenteObstetricoShow},
    methods:{
        async cargarUsuario(id) {
            const response = await usuarioService.show(id);
            this.usuario = response.data;
        },
        async cargarUltimoEmbarazo(id){
            const response = await embarazoService.showByLast(id);
            this.ultimo_embarazo= response.data;
        },
        async download() {
            /* let pdfRef = this.$refs.resumen;
            const options = {type: 'dataURL'};
            const imgData = await this.$html2canvas(pdfRef, options);
            this.imgData = imgData */

            

            var doc = new jsPDF('p', 'mm', [297, 210]);

            doc.html(document.querySelector('#resumen'), {
                callback: function (doc) {
                    doc.save();
                },
                x: 10,
                y: 10,
                width: 190,
                windowWidth : 1000,
            });
        },
    },
    data () {
        return {
            imgData : '',
            idHistoria:'',
            idUsuario:'',
            usuario: {},
            ultimo_embarazo:{}
        }
    },
 watch:{
        idUsuario : async function(){
           const response = await historiaClinicaService.show(this.idHistoria);
            this.idUsuario=response.data.id_usuario;
        },
        idHistoria : async function(){
           this.idHistoria=this.$route.params.id;
        }
    },
     async created () {
        this.idHistoria=this.$route.params.id;

        const response = await historiaClinicaService.show(this.idHistoria);
        this.idUsuario=response.data.id_usuario;
        await this.cargarUsuario(this.idUsuario);
       await this.cargarUltimoEmbarazo(this.idUsuario);

    },
}
</script>
<style scoped>
.resumen-container {
    height:300px;
    overflow-y:scroll
}

</style>