<template>
    <main>
        <div class="">
            <div class="card-header bg-light "><span><i class="fa fa-diagnoses"> </i> Antecedentes Obstetricos</span> </div>
            <div class="card-body">
                <div class="form-row">
                    <div class="form-group col-lg-4 col-md-4 col-sm-12">
                        <label class="form-label">GESTAS : {{antecedente_ob.gestas}} </label>
                    </div>
                    <div class="form-group col-lg-4 col-md-4 col-sm-12">
                        <label class="form-label">PARTOS : {{antecedente_ob.partos}}</label>
                    </div>
                    <div class="form-group col-lg-4 col-md-4 col-sm-12">
                        <label class="form-label">CESAREAS : {{antecedente_ob.cesareas}}</label>
                    </div>
                    <div class="form-group col-lg-4 col-md-4 col-sm-12">
                        <label class="form-label">ABORTOS : {{antecedente_ob.abortos}}</label>
                    </div>
                    <div class="form-group col-lg-4 col-md-4 col-sm-12">
                        <label class="form-label">VAGINALES : {{antecedente_ob.vaginales}}</label>
                    </div>
                    <div class="form-group col-lg-4 col-md-4 col-sm-12">
                        <label class="form-label">MAS DE 3 PARTOS ESPONTANEOS : {{antecedente_ob.partos_espontaneos}}</label>
                    </div>
                    <div class="form-group col-lg-4 col-md-4 col-sm-12">
                        <label class="form-label">NACIDOS VIVOS : {{antecedente_ob.nacidos_vivos}}</label>
                    </div>
                    <div class="form-group col-lg-4 col-md-4 col-sm-12">
                        <label class="form-label">VIVEN : {{antecedente_ob.viven}}</label>
                    </div>
                    <div class="form-group col-lg-4 col-md-4 col-sm-12">
                        <label class="form-label">NACIDOS MUERTOS : {{antecedente_ob.nacidos_muertos}}</label>
                    </div>
                    <div class="form-group col-lg-4 col-md-4 col-sm-12">
                        <label class="form-label">MUERTOS 1 SEM : {{antecedente_ob.muertos}}</label>
                    </div>
                    <div class="form-group col-lg-4 col-md-4 col-sm-12">
                        <label class="form-label">RN MENOR DE 2500 GR : {{antecedente_ob.rn_menor}}</label>
                    </div>
                    <div class="form-group col-lg-4 col-md-4 col-sm-12">
                        <label class="form-label">RN MAYOR PESO GR : {{antecedente_ob.rn_mayor}}</label>
                    </div>
                    <div class="form-group col-lg-4 col-md-4 col-sm-12">
                        <label class="form-label">GEMELARES : {{antecedente_ob.gemelares}}</label>
                    </div>
                    <div class="form-group col-lg-4 col-md-4 col-sm-12">
                        <label class="form-label">FECHA ANT EMBARAZO : {{antecedente_ob.fecha_anterior | formatDate}}</label>
                    </div>
                    <div class="form-group col-lg-4 col-md-4 col-sm-12">
                        <label class="form-label">MUERTOS DESP 1 SEM : {{antecedente_ob.muertos_despues}}</label>
                    </div>
                    <div class="form-group col-lg-4 col-md-4 col-sm-12">
                        <label class="form-label">RPM : {{antecedente_ob.rpm}}</label>
                    </div>
                    <div class="form-group col-lg-4 col-md-4 col-sm-12">
                        <label class="form-label">RCIU : {{antecedente_ob.rciu}}</label>
                    </div>
                    <div class="form-group col-lg-4 col-md-4 col-sm-12">
                        <label class="form-label">AMENAZA PP : {{antecedente_ob.amenaza_pp}}</label>
                    </div>
                    <div class="form-group col-lg-4 col-md-4 col-sm-12">
                        <label class="form-label">MALFORMACION : {{antecedente_ob.malformacion}}</label>
                    </div>
                    <div class="form-group col-lg-4 col-md-4 col-sm-12">
                        <label class="form-label">OLIGOHIDRAMNIOS : {{antecedente_ob.oligohidramnios}}</label>
                    </div>
                    <div class="form-group col-lg-4 col-md-4 col-sm-12">
                        <label class="form-label">EMB. PROLONGADO : {{antecedente_ob.embarazo_prolongado}}</label>
                    </div>
                    <div class="form-group col-lg-4 col-md-4 col-sm-12">
                        <label class="form-label">AMENAZA ABORTO : {{antecedente_ob.amenaza_aborto}}</label>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import {isEmpty} from "lodash";
import antecedentesObstetricosService from "../../../../services/antecedentesObstetricosService";
export default {
    props: ['idEmbarazo'],
    data(){
        return {
            antecedente_ob:{
                gestas:'',
                partos:'',
                cesareas:'',
                abortos:'',
                vaginales:'',
                partos_espontaneos:'',
                nacidos_vivos:'',
                viven:'',
                nacidos_muertos:'',
                muertos:'',
                rn_menor:'',
                rn_mayor:'',
                gemelares:'',
                fecha_anterior:'',
                muertos_despues:'',
                rpm:'',
                rciu:'',
                amenaza_pp:'',
                malformacion:'',
                oligohidramnios:'',
                embarazo_prolongado:'',
                amenaza_aborto:'',
            }
        }
    },
    watch: {
        antecedentes : async function (){
            const response = await antecedentesObstetricosService.showByIdEmbarazo(this.idEmbarazo);
            if(!isEmpty(response.data)){
            this.antecedente_ob.gestas=response.data.gestas;
                this.antecedente_ob.partos=response.data.partos;
                this.antecedente_ob.cesareas=response.data.cesareas;
                this.antecedente_ob.abortos=response.data.abortos;
                this.antecedente_ob.vaginales=response.data.vaginales;
                this.antecedente_ob.partos_espontaneos=response.data.partos_espontaneos;
                this.antecedente_ob.nacidos_vivos=response.data.nacidos_vivos;
                this.antecedente_ob.viven=response.data.viven;
                this.antecedente_ob.nacidos_muertos=response.data.nacidos_muertos;
                this.antecedente_ob.muertos=response.data.muertos;
                this.antecedente_ob.rn_menor=response.data.rn_menor;
                this.antecedente_ob.rn_mayor=response.data.rn_mayor;
                this.antecedente_ob.gemelares=response.data.gemelares;
                this.antecedente_ob.fecha_anterior=response.data.fecha_anterior;
                this.antecedente_ob.muertos_despues=response.data.muertos_despues;
                this.antecedente_ob.rpm=response.data.rpm;
                this.antecedente_ob.rciu=response.data.rciu;
                this.antecedente_ob.amenaza_pp=response.data.amenaza_pp;
                this.antecedente_ob.malformacion=response.data.malformacion;
                this.antecedente_ob.oligohidramnios=response.data.oligohidramnios;
                this.antecedente_ob.embarazo_prolongado=response.data.embarazo_prolongado;
                this.antecedente_ob.amenaza_aborto=response.data.amenaza_aborto;
            }
        }
    },
   async created() {
        const response = await antecedentesObstetricosService.showByIdEmbarazo(this.idEmbarazo);
        if(!isEmpty(response.data)){
           this.antecedente_ob.gestas=response.data.gestas;
            this.antecedente_ob.partos=response.data.partos;
            this.antecedente_ob.cesareas=response.data.cesareas;
            this.antecedente_ob.abortos=response.data.abortos;
            this.antecedente_ob.vaginales=response.data.vaginales;
            this.antecedente_ob.partos_espontaneos=response.data.partos_espontaneos;
            this.antecedente_ob.nacidos_vivos=response.data.nacidos_vivos;
            this.antecedente_ob.viven=response.data.viven;
            this.antecedente_ob.nacidos_muertos=response.data.nacidos_muertos;
            this.antecedente_ob.muertos=response.data.muertos;
            this.antecedente_ob.rn_menor=response.data.rn_menor;
            this.antecedente_ob.rn_mayor=response.data.rn_mayor;
            this.antecedente_ob.gemelares=response.data.gemelares;
            this.antecedente_ob.fecha_anterior=response.data.fecha_anterior;
            this.antecedente_ob.muertos_despues=response.data.muertos_despues;
            this.antecedente_ob.rpm=response.data.rpm;
            this.antecedente_ob.rciu=response.data.rciu;
            this.antecedente_ob.amenaza_pp=response.data.amenaza_pp;
            this.antecedente_ob.malformacion=response.data.malformacion;
            this.antecedente_ob.oligohidramnios=response.data.oligohidramnios;
            this.antecedente_ob.embarazo_prolongado=response.data.embarazo_prolongado;
            this.antecedente_ob.amenaza_aborto=response.data.amenaza_aborto;
        }
    }
}
</script>