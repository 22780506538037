import Service from "./Service";
const baseurl = '/api/antecedentes-obstetricos';
export default {
    store(obj) {
        return Service.post(`${baseurl}/store`, obj);
    },
    show(id) {
        return Service.get(`${baseurl}/show/${id}`);
    },
    showByIdEmbarazo(id) {
        return Service.get(`${baseurl}/show-by-idEmbarazo/${id}`);
    },
    update(obj) {
        return Service.put(`${baseurl}/update`, obj);
    },
}